@charset "UTF-8";

:root {
  --star-size: 3ch;
  --star-color: rgba(0, 0, 0, 0.1);
  --star-background: rgb(14, 11, 51);
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  40% {
    transform: translatey(-6px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  40% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
body {
  background: #f8fafc;
  font-family: "roboto", "sans-serif";
  font-weight: 400;
  padding: 0;
  margin: 0;
}

a:link, a:visited, a:active, a:hover {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

ul, ol {
  list-style: none;
}

.list-inline li, .list-tabs li {
  display: inline-block;
}
.list-inline li + li, .list-tabs li + li {
  margin-left: 1rem;
}

.space li + li {
  margin-top: 0.5rem;
}

dt {
  width: 25%;
}

dd {
  width: 75%;
}

dt, dd {
  display: inline-block;
  font-size: 0.75rem;
  margin: 0;
}

button {
  cursor: pointer;
}

[class^=input--],
select {
  border: 1px solid black;
  border-radius: 0.25rem;
}

button, select, [class^=input--] {
  font-size: 0.75rem;
  background: white;
  text-transform: uppercase;
  padding: 0.25rem 0.5rem;
}

select {
  height: 1.625rem;
}

[class^=cta--] {
  border-radius: 0.25rem;
  border: none;
}

.cta--accept {
  background: green;
  color: white;
}

.cta--reject {
  background: red;
  color: white;
}

.selected {
  background: black;
  color: white;
}

.notification--bubble {
  font-size: 0.625rem;
  font-weight: normal;
  min-height: 1rem;
  min-width: 1rem;
  line-height: 1rem;
}

.notification--calendar {
  font-size: 0.75rem;
  height: 1rem;
  padding: 0.25rem;
  width: 1rem;
  border: 1px solid black;
  border-top: 0.625rem solid black;
  padding: 0 0.125rem;
  position: relative;
}

.calendar-month {
  position: absolute;
  top: -0.625rem;
  left: 0;
  right: 0;
  font-size: 0.5625rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1rem;
}

.blur {
  color: transparent;
  text-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.5);
  user-select: none;
}

.visually-hidden {
  position: absolute;
  left: -100vw;
}

.card {
  background: white;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.card__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.monospace {
  font-family: "roboto-mono", monospace;
}

.quiet, .fixture-row__attendance, .fixture-row__date {
  color: rgba(0, 0, 0, 0.5);
}

.last-five {
  display: flex;
  flex-direction: row;
}

.last-five__match {
  text-align: center;
}
.last-five__match > p {
  padding: 0.5rem 0;
}

.no-compact {
  display: inline-flex;
}

form {
  display: block;
  padding: 2ch 0;
}

label {
  cursor: pointer;
  display: inline-flex;
}

label + input {
  margin-left: 2ch;
}

table + table {
  margin-top: 2rem;
}

th {
  text-align: left;
}

td, th {
  font-family: roboto, sans-serif;
}

caption {
  caption-side: top;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-family: rift, sans-serif;
  font-size: 1rem;
  font-weight: 900;
}

[class^=table] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  width: 100%;
  border-spacing: 0;
  border-radius: 0.25rem;
  overflow: hidden;
}

.card [class^=table] {
  border: 0 none;
}

[class^=table--] th {
  background: #0e0b33;
  color: white;
  font-weight: normal;
}

[class^=table--] th,
[class^=table--] td {
  margin: 0;
  padding: 0.5rem;
  vertical-align: middle;
}

tr:nth-child(odd) td {
  background: hsl(44, 100%, 98%);
}

.table--league tr:nth-child(1) td,
.lower-division tr:nth-child(n+2):nth-child(-n+4) td {
  background: hsl(44, 100%, 90%);
}

.table--league a {
  color: black;
  text-decoration: none;
}
.table--league tr:nth-child(n+17) td {
  background: hsla(0, 100%, 90%, 0.3);
}

[class^=table--lineup-] tr td {
  background: none;
}

[class^=table--lineup-] tr:hover td {
  background: hsl(44, 100%, 98%);
}

[class^=table--lineup-] :nth-child(12) {
  border-top: 1px dashed black;
}

.sort-by::after {
  content: " ▽";
}

.sorted::after {
  content: " ▼";
}

.team-selection,
.match-result,
.generic-main-table {
  background: white;
  padding: 1rem;
  margin: 1rem;
}

.match-heading,
.page-heading,
.generic-heading {
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;
}

.page-heading {
  margin-bottom: 0;
}

.scoreline {
  padding: 1rem;
  background: hsl(111, 100%, 98%);
}

.card-and-detail {
  display: flex;
}
.card-and-detail > aside {
  flex: 0 1 10%;
}

.cta-holder--r {
  float: right;
}

.mb1 {
  margin-bottom: 1rem;
}

@media all and (max-width: 896px) {
  .hamburger {
    z-index: 30;
    position: fixed;
    top: 1rem;
    right: 1rem;
    line-height: 1rem;
  }
  .hamburger::before {
    content: "≡";
    font-size: 3rem;
  }
}
#nav-open:checked ~ .nav-links {
  display: block;
}

.divider {
  margin: 10ch;
}

.nav--horizontal {
  margin: 0;
  margin-block: 0;
  padding-inline-start: 0;
}

.dropdown-toggle {
  position: relative;
}
.dropdown-toggle .dropdown-menu {
  display: none;
  left: 0;
  position: absolute;
}
.dropdown-toggle:hover .dropdown-menu {
  display: block;
}

.overview {
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20.3125rem, 1fr));
}

.nav__section-heading [class^=icon-] {
  float: left;
  margin-left: 1rem;
  height: 1rem;
  width: 1rem;
}

.nav--horizontal a {
  color: white;
  padding: 1rem;
  display: flex;
}
.nav--horizontal .standalone {
  padding: 0;
}
.nav--horizontal h4 {
  color: white;
}

.nav {
  border-radius: 0 0 0 0.625rem;
  padding: 0;
}

.nav, .page {
  overflow: hidden;
}

.subnav {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  list-style: none;
  z-index: 20;
  position: sticky;
  top: 0;
  justify-content: space-evenly;
  color: white;
}
.subnav, .subnav li {
  background: #CC5500;
}
.subnav a {
  color: white;
}

.club-selection {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 11rem;
  border: 0.1rem solid #A4A6A7;
  padding: 0.8rem;
  text-align: center;
  margin: 0 0 2% 2%;
}

@-webkit-keyframes rotating {
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  display: inline-block;
}

.bouncing {
  animation: float 0.5s linear infinite;
  display: inline-block;
}

@-webkit-keyframes rotating-diamond {
  from {
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
@keyframes rotating-diamond {
  from {
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  to {
    -ms-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
.winning-team {
  font-weight: 700;
}

.non-winning-team {
  font-weight: 100;
}

.action {
  padding: 1rem;
  text-align: center;
  position: relative;
}

.action::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid gray;
  z-index: 0;
}

.btn {
  background: white;
  border: 1px solid black;
  cursor: pointer;
  font-size: 0.625rem;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.btn:hover {
  background: black;
  color: white;
}

.btn.first {
  background: #f58162;
  color: white;
}

.btn.second, .btn.first {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  z-index: 10;
}

.btn.disabled {
  background: #EEEEEE;
  cursor: default;
}

.accounting {
  text-align: right;
}
.accounting::before {
  content: "£";
}

.todo {
  background: yellow;
}

.nav {
  display: flex;
  align-items: center;
}
.nav a {
  text-decoration: none;
}
.nav a, .nav a:visited {
  color: black;
}

.active {
  font-weight: 700;
}

.nav__section-heading {
  margin: 1.5rem 0 0;
  display: block;
}

.nav-links {
  font-size: 0.75rem;
  padding: 0;
  margin: 0;
}
.nav-links .nav-links {
  padding: 0 1rem 1.25rem 2.8125rem;
}

.nav-link + .nav-link {
  margin: 1rem 0 0;
}

.nav-link--action {
  border-bottom: 1px solid #dcf0f7;
  padding: 1.5rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: white;
}

.nav-info {
  display: block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1rem;
  font-size: 0.7rem;
}

.standalone {
  font-weight: bold;
  display: block;
}

.standalone + .standalone {
  margin-top: 0.5rem;
}

.standalone::before {
  content: ">> ";
}

.nav--horizontal [class^=icon-] {
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

[data-rating*="80"] {
  font-weight: 500;
}
[data-rating*="80"] .player-star::before {
  content: "☆";
}

[data-rating*="90"] {
  font-weight: 700;
}
[data-rating*="90"] .player-star::before {
  content: "★";
}

[data-player-rating] {
  padding: 0.125rem;
}
[data-player-rating]::before {
  display: inline-block;
  content: "";
  height: 1ch;
  width: 1ch;
  border-radius: 50%;
}

[data-player-rating="10"]::before {
  background-color: rgb(245, 100, 100);
}

[data-player-rating="20"]::before {
  background-color: rgb(235, 255, 255);
}

[data-player-rating="30"]::before {
  background-color: rgb(225, 255, 255);
}

[data-player-rating="40"]::before {
  background-color: rgb(215, 255, 255);
}

[data-player-rating="50"]::before {
  background-color: rgb(205, 255, 255);
}

[data-player-rating="60"]::before {
  background-color: rgb(195, 255, 255);
}

[data-player-rating="70"]::before {
  background-color: rgb(185, 255, 255);
}

[data-player-rating="80"]::before {
  background-color: rgb(175, 255, 255);
}

[data-player-rating="90"]::before {
  background-color: rgb(165, 255, 255);
}

[data-player-rating="100"]::before {
  background-color: rgb(155, 255, 255);
}

.overall[data-player-rating]::before {
  width: 6ch;
  border-radius: 2ch;
}

[class^=win--inline],
[class^=lose--inline],
[class^=draw--inline] {
  float: left;
  margin-right: 1rem;
  height: 0.5rem;
  width: 0.5rem;
}

[class^=win],
[class^=lose],
[class^=draw] {
  display: inline-block;
  border-radius: 50%;
}

.win--big, .lose--big, .draw--big {
  height: 1rem;
  width: 1rem;
  float: none;
}

[class^=win]:not(.winning-team) {
  background: #4e9779;
}

[class^=lose] {
  background: #e7471c;
}

[class^=draw] {
  background: #b2ffee;
}

.club-selector {
  min-height: 5rem;
  text-align: center;
}

.raise {
  padding: 1rem;
}

.punch {
  border: 0.1rem solid greenyellow;
  border-radius: 50%;
  padding: 0.5rem;
}

.club-colors {
  position: relative;
  height: 2rem;
  width: 2rem;
  margin: 1rem 0 2rem 50%;
  height: 2rem;
  width: 2rem;
  top: -0.3rem;
  left: -0.3rem;
  bottom: -0.2rem;
  right: -0.2rem;
  height: 1rem;
  width: 1rem;
  top: -0.5rem;
  left: -0.5rem;
  bottom: -0.5rem;
  right: -0.5rem;
}
.club-colors > div {
  transform: skew(0, -5deg);
  position: absolute;
}
.club-colors > div:nth-child(2) {
  margin-bottom: -0.25rem;
}
.previous-next {
  position: relative;
}
.previous-next .previous, .previous-next .next {
  position: absolute;
}
.previous-next .previous {
  left: 0;
}
.previous-next .next {
  right: 0;
}

[class*=-shield]:before,
[class*=-concentric]:before {
  content: "";
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}

[class*=-concentric-loading]:before {
  animation: float 0.5s linear infinite;
  display: inline-block;
}

[class*=-shield-loading]:before {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  display: inline-block;
}

[class*=-shield]:before {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0 0 50% 50%;
  background: hsl(108, 91%, 22%);
  background-image: radial-gradient(circle, hsl(0, 86%, 84%) 40%, transparent 40%), radial-gradient(circle, hsl(206, 70%, 73%) 40%, transparent 40%), radial-gradient(circle, hsl(58, 98%, 51%) 40%, transparent 40%);
  background-position: 15% 20%, 85% 20%, 50% 80%;
  background-size: 0.375rem 0.375rem, 0.375rem 0.375rem, 0.375rem 0.375rem;
  background-repeat: no-repeat;
}

[class^=union-concentric]::before {
  background-color: hsl(0, 0%, 83%);
  box-shadow: inset 0 0 0 0.1875rem hsl(236, 71%, 55%), inset 0 0 0 0.375rem hsl(55, 100%, 49%);
}

[class^=lions-concentric]::before {
  background-color: white;
  box-shadow: inset 0 0 0 0.1875rem hsl(8, 61%, 49%), inset 0 0 0 0.375rem hsl(50, 98%, 49%);
}

[class*="--right"]::before {
  float: right;
  margin-left: 1rem;
}

[class*="--right"] {
  text-align: right;
}

.fill--twins {
  fill: hsl(194, 45%, 79%);
}

[class^=twins-]:before {
  margin-right: 1rem;
}

[class^=twins-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=twins-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(194, 45%, 79%);
  border-left-color: hsl(30, 93%, 66%);
  border-top-color: hsl(155, 32%, 45%);
  border-bottom-color: hsl(0, 78%, 89%);
}

td[class^=twins-diamond]:before {
  border-width: 0.375rem;
}

.twins--primary {
  background: hsl(194, 45%, 79%);
}

.twins--secondary {
  background: hsl(30, 93%, 66%);
}

.twins--away {
  background: hsl(155, 32%, 45%);
}

.twins--other {
  background: hsl(0, 78%, 89%);
}

.fill--cosmos {
  fill: hsl(237, 73%, 35%);
}

[class^=cosmos-]:before {
  margin-right: 1rem;
}

[class^=cosmos-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=cosmos-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(237, 73%, 35%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(0, 100%, 49%);
  border-bottom-color: hsl(202, 100%, 52%);
}

td[class^=cosmos-diamond]:before {
  border-width: 0.375rem;
}

.cosmos--primary {
  background: hsl(237, 73%, 35%);
}

.cosmos--secondary {
  background: hsl(0, 0%, 100%);
}

.cosmos--away {
  background: hsl(0, 100%, 49%);
}

.cosmos--other {
  background: hsl(202, 100%, 52%);
}

.fill--giants {
  fill: hsl(203, 7%, 53%);
}

[class^=giants-]:before {
  margin-right: 1rem;
}

[class^=giants-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=giants-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(203, 7%, 53%);
  border-left-color: hsl(201, 32%, 88%);
  border-top-color: hsl(0, 0%, 100%);
  border-bottom-color: hsl(0, 0%, 33%);
}

td[class^=giants-diamond]:before {
  border-width: 0.375rem;
}

.giants--primary {
  background: hsl(203, 7%, 53%);
}

.giants--secondary {
  background: hsl(201, 32%, 88%);
}

.giants--away {
  background: hsl(0, 0%, 100%);
}

.giants--other {
  background: hsl(0, 0%, 33%);
}

.fill--loco {
  fill: hsl(11, 48%, 35%);
}

[class^=loco-]:before {
  margin-right: 1rem;
}

[class^=loco-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=loco-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(11, 48%, 35%);
  border-left-color: hsl(11, 3%, 17%);
  border-top-color: hsl(40, 100%, 17%);
  border-bottom-color: hsl(40, 33%, 67%);
}

td[class^=loco-diamond]:before {
  border-width: 0.375rem;
}

.loco--primary {
  background: hsl(11, 48%, 35%);
}

.loco--secondary {
  background: hsl(11, 3%, 17%);
}

.loco--away {
  background: hsl(40, 100%, 17%);
}

.loco--other {
  background: hsl(40, 33%, 67%);
}

.fill--eagle {
  fill: hsl(43, 100%, 38%);
}

[class^=eagle-]:before {
  margin-right: 1rem;
}

[class^=eagle-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=eagle-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(43, 100%, 38%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(11, 98%, 40%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=eagle-diamond]:before {
  border-width: 0.375rem;
}

.eagle--primary {
  background: hsl(43, 100%, 38%);
}

.eagle--secondary {
  background: hsl(0, 0%, 100%);
}

.eagle--away {
  background: hsl(11, 98%, 40%);
}

.eagle--other {
  background: hsl(0, 0%, 100%);
}

.fill--dragons {
  fill: hsl(0, 100%, 48%);
}

[class^=dragons-]:before {
  margin-right: 1rem;
}

[class^=dragons-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=dragons-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 100%, 48%);
  border-left-color: hsl(0, 0%, 0%);
  border-top-color: hsl(28, 100%, 50%);
  border-bottom-color: hsl(51, 100%, 63%);
}

td[class^=dragons-diamond]:before {
  border-width: 0.375rem;
}

.dragons--primary {
  background: hsl(0, 100%, 48%);
}

.dragons--secondary {
  background: hsl(0, 0%, 0%);
}

.dragons--away {
  background: hsl(28, 100%, 50%);
}

.dragons--other {
  background: hsl(51, 100%, 63%);
}

.fill--tortugas {
  fill: hsl(229, 37%, 40%);
}

[class^=tortugas-]:before {
  margin-right: 1rem;
}

[class^=tortugas-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=tortugas-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(229, 37%, 40%);
  border-left-color: hsl(40, 97%, 56%);
  border-top-color: hsl(180, 100%, 50%);
  border-bottom-color: hsl(203, 100%, 50%);
}

td[class^=tortugas-diamond]:before {
  border-width: 0.375rem;
}

.tortugas--primary {
  background: hsl(229, 37%, 40%);
}

.tortugas--secondary {
  background: hsl(40, 97%, 56%);
}

.tortugas--away {
  background: hsl(180, 100%, 50%);
}

.tortugas--other {
  background: hsl(203, 100%, 50%);
}

.fill--delys {
  fill: hsl(236, 63%, 37%);
}

[class^=delys-]:before {
  margin-right: 1rem;
}

[class^=delys-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=delys-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(236, 63%, 37%);
  border-left-color: hsl(57, 90%, 59%);
  border-top-color: hsl(0, 0%, 100%);
  border-bottom-color: hsl(0, 92%, 47%);
}

td[class^=delys-diamond]:before {
  border-width: 0.375rem;
}

.delys--primary {
  background: hsl(236, 63%, 37%);
}

.delys--secondary {
  background: hsl(57, 90%, 59%);
}

.delys--away {
  background: hsl(0, 0%, 100%);
}

.delys--other {
  background: hsl(0, 92%, 47%);
}

.fill--union {
  fill: hsl(55, 100%, 49%);
}

[class^=union-]:before {
  margin-right: 1rem;
}

[class^=union-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=union-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(55, 100%, 49%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(236, 71%, 55%);
  border-bottom-color: hsl(0, 0%, 83%);
}

td[class^=union-diamond]:before {
  border-width: 0.375rem;
}

.union--primary {
  background: hsl(55, 100%, 49%);
}

.union--secondary {
  background: hsl(0, 0%, 100%);
}

.union--away {
  background: hsl(236, 71%, 55%);
}

.union--other {
  background: hsl(0, 0%, 83%);
}

.fill--saints {
  fill: hsl(0, 0%, 100%);
}

[class^=saints-]:before {
  margin-right: 1rem;
}

[class^=saints-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=saints-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 0%, 100%);
  border-left-color: hsl(0, 95%, 53%);
  border-top-color: hsl(194, 95%, 49%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=saints-diamond]:before {
  border-width: 0.375rem;
}

.saints--primary {
  background: hsl(0, 0%, 100%);
}

.saints--secondary {
  background: hsl(0, 95%, 53%);
}

.saints--away {
  background: hsl(194, 95%, 49%);
}

.saints--other {
  background: hsl(0, 0%, 100%);
}

.fill--irons {
  fill: hsl(208, 45%, 32%);
}

[class^=irons-]:before {
  margin-right: 1rem;
}

[class^=irons-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=irons-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(208, 45%, 32%);
  border-left-color: hsl(54, 98%, 51%);
  border-top-color: hsl(0, 0%, 100%);
  border-bottom-color: hsl(0, 63%, 49%);
}

td[class^=irons-diamond]:before {
  border-width: 0.375rem;
}

.irons--primary {
  background: hsl(208, 45%, 32%);
}

.irons--secondary {
  background: hsl(54, 98%, 51%);
}

.irons--away {
  background: hsl(0, 0%, 100%);
}

.irons--other {
  background: hsl(0, 63%, 49%);
}

.fill--shamrock {
  fill: hsl(150, 95%, 31%);
}

[class^=shamrock-]:before {
  margin-right: 1rem;
}

[class^=shamrock-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=shamrock-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(150, 95%, 31%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(18, 95%, 49%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=shamrock-diamond]:before {
  border-width: 0.375rem;
}

.shamrock--primary {
  background: hsl(150, 95%, 31%);
}

.shamrock--secondary {
  background: hsl(0, 0%, 100%);
}

.shamrock--away {
  background: hsl(18, 95%, 49%);
}

.shamrock--other {
  background: hsl(0, 0%, 100%);
}

.fill--lions {
  fill: hsl(50, 98%, 49%);
}

[class^=lions-]:before {
  margin-right: 1rem;
}

[class^=lions-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=lions-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(50, 98%, 49%);
  border-left-color: hsl(8, 61%, 49%);
  border-top-color: hsl(0, 0%, 100%);
  border-bottom-color: hsl(191, 92%, 72%);
}

td[class^=lions-diamond]:before {
  border-width: 0.375rem;
}

.lions--primary {
  background: hsl(50, 98%, 49%);
}

.lions--secondary {
  background: hsl(8, 61%, 49%);
}

.lions--away {
  background: hsl(0, 0%, 100%);
}

.lions--other {
  background: hsl(191, 92%, 72%);
}

.fill--palms {
  fill: hsl(0, 86%, 84%);
}

[class^=palms-]:before {
  margin-right: 1rem;
}

[class^=palms-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=palms-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 86%, 84%);
  border-left-color: hsl(108, 91%, 22%);
  border-top-color: hsl(206, 70%, 73%);
  border-bottom-color: hsl(58, 98%, 51%);
}

td[class^=palms-diamond]:before {
  border-width: 0.375rem;
}

.palms--primary {
  background: hsl(0, 86%, 84%);
}

.palms--secondary {
  background: hsl(108, 91%, 22%);
}

.palms--away {
  background: hsl(206, 70%, 73%);
}

.palms--other {
  background: hsl(58, 98%, 51%);
}

.fill--lotus {
  fill: hsl(338, 98%, 49%);
}

[class^=lotus-]:before {
  margin-right: 1rem;
}

[class^=lotus-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=lotus-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(338, 98%, 49%);
  border-left-color: hsl(342, 81%, 88%);
  border-top-color: hsl(236, 43%, 37%);
  border-bottom-color: hsl(161, 95%, 75%);
}

td[class^=lotus-diamond]:before {
  border-width: 0.375rem;
}

.lotus--primary {
  background: hsl(338, 98%, 49%);
}

.lotus--secondary {
  background: hsl(342, 81%, 88%);
}

.lotus--away {
  background: hsl(236, 43%, 37%);
}

.lotus--other {
  background: hsl(161, 95%, 75%);
}

.fill--bears {
  fill: hsl(56, 92%, 49%);
}

[class^=bears-]:before {
  margin-right: 1rem;
}

[class^=bears-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=bears-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(56, 92%, 49%);
  border-left-color: hsl(160, 69%, 37%);
  border-top-color: hsl(194, 100%, 49%);
  border-bottom-color: hsl(7, 95%, 50%);
}

td[class^=bears-diamond]:before {
  border-width: 0.375rem;
}

.bears--primary {
  background: hsl(56, 92%, 49%);
}

.bears--secondary {
  background: hsl(160, 69%, 37%);
}

.bears--away {
  background: hsl(194, 100%, 49%);
}

.bears--other {
  background: hsl(7, 95%, 50%);
}

.fill--hornets {
  fill: hsl(57, 100%, 52%);
}

[class^=hornets-]:before {
  margin-right: 1rem;
}

[class^=hornets-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=hornets-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(57, 100%, 52%);
  border-left-color: hsl(0, 0%, 0%);
  border-top-color: hsl(160, 95%, 35%);
  border-bottom-color: hsl(99, 89%, 61%);
}

td[class^=hornets-diamond]:before {
  border-width: 0.375rem;
}

.hornets--primary {
  background: hsl(57, 100%, 52%);
}

.hornets--secondary {
  background: hsl(0, 0%, 0%);
}

.hornets--away {
  background: hsl(160, 95%, 35%);
}

.hornets--other {
  background: hsl(99, 89%, 61%);
}

.fill--motors {
  fill: hsl(0, 0%, 0%);
}

[class^=motors-]:before {
  margin-right: 1rem;
}

[class^=motors-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=motors-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 0%, 0%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(260, 61%, 71%);
  border-bottom-color: hsl(259, 75%, 38%);
}

td[class^=motors-diamond]:before {
  border-width: 0.375rem;
}

.motors--primary {
  background: hsl(0, 0%, 0%);
}

.motors--secondary {
  background: hsl(0, 0%, 100%);
}

.motors--away {
  background: hsl(260, 61%, 71%);
}

.motors--other {
  background: hsl(259, 75%, 38%);
}

.fill--empress {
  fill: hsl(128, 95%, 22%);
}

[class^=empress-]:before {
  margin-right: 1rem;
}

[class^=empress-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=empress-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(128, 95%, 22%);
  border-left-color: hsl(40, 98%, 50%);
  border-top-color: hsl(7, 100%, 60%);
  border-bottom-color: hsl(219, 50%, 43%);
}

td[class^=empress-diamond]:before {
  border-width: 0.375rem;
}

.empress--primary {
  background: hsl(128, 95%, 22%);
}

.empress--secondary {
  background: hsl(40, 98%, 50%);
}

.empress--away {
  background: hsl(7, 100%, 60%);
}

.empress--other {
  background: hsl(219, 50%, 43%);
}

.fill--park {
  fill: hsl(240, 95%, 50%);
}

[class^=park-]:before {
  margin-right: 1rem;
}

[class^=park-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=park-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(240, 95%, 50%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(31, 95%, 50%);
  border-bottom-color: hsl(102, 70%, 40%);
}

td[class^=park-diamond]:before {
  border-width: 0.375rem;
}

.park--primary {
  background: hsl(240, 95%, 50%);
}

.park--secondary {
  background: hsl(0, 0%, 100%);
}

.park--away {
  background: hsl(31, 95%, 50%);
}

.park--other {
  background: hsl(102, 70%, 40%);
}

.fill--racing {
  fill: hsl(4, 95%, 43%);
}

[class^=racing-]:before {
  margin-right: 1rem;
}

[class^=racing-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=racing-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(4, 95%, 43%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(0, 0%, 0%);
  border-bottom-color: hsl(27, 95%, 50%);
}

td[class^=racing-diamond]:before {
  border-width: 0.375rem;
}

.racing--primary {
  background: hsl(4, 95%, 43%);
}

.racing--secondary {
  background: hsl(0, 0%, 100%);
}

.racing--away {
  background: hsl(0, 0%, 0%);
}

.racing--other {
  background: hsl(27, 95%, 50%);
}

.fill--hombres {
  fill: hsl(218, 96%, 36%);
}

[class^=hombres-]:before {
  margin-right: 1rem;
}

[class^=hombres-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=hombres-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(218, 96%, 36%);
  border-left-color: hsl(180, 96%, 38%);
  border-top-color: hsl(231, 81%, 87%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=hombres-diamond]:before {
  border-width: 0.375rem;
}

.hombres--primary {
  background: hsl(218, 96%, 36%);
}

.hombres--secondary {
  background: hsl(180, 96%, 38%);
}

.hombres--away {
  background: hsl(231, 81%, 87%);
}

.hombres--other {
  background: hsl(0, 0%, 100%);
}

.fill--fortezza {
  fill: hsl(174, 95%, 23%);
}

[class^=fortezza-]:before {
  margin-right: 1rem;
}

[class^=fortezza-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=fortezza-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(174, 95%, 23%);
  border-left-color: hsl(37, 95%, 52%);
  border-top-color: hsl(196, 96%, 69%);
  border-bottom-color: hsl(234, 97%, 60%);
}

td[class^=fortezza-diamond]:before {
  border-width: 0.375rem;
}

.fortezza--primary {
  background: hsl(174, 95%, 23%);
}

.fortezza--secondary {
  background: hsl(37, 95%, 52%);
}

.fortezza--away {
  background: hsl(196, 96%, 69%);
}

.fortezza--other {
  background: hsl(234, 97%, 60%);
}

.fill--chevaliers {
  fill: hsl(211, 60%, 52%);
}

[class^=chevaliers-]:before {
  margin-right: 1rem;
}

[class^=chevaliers-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=chevaliers-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(211, 60%, 52%);
  border-left-color: hsl(0, 94%, 60%);
  border-top-color: hsl(0, 0%, 100%);
  border-bottom-color: hsl(102, 42%, 39%);
}

td[class^=chevaliers-diamond]:before {
  border-width: 0.375rem;
}

.chevaliers--primary {
  background: hsl(211, 60%, 52%);
}

.chevaliers--secondary {
  background: hsl(0, 94%, 60%);
}

.chevaliers--away {
  background: hsl(0, 0%, 100%);
}

.chevaliers--other {
  background: hsl(102, 42%, 39%);
}

.fill--jackdaws {
  fill: hsl(0, 0%, 0%);
}

[class^=jackdaws-]:before {
  margin-right: 1rem;
}

[class^=jackdaws-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=jackdaws-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 0%, 0%);
  border-left-color: hsl(264, 12%, 58%);
  border-top-color: hsl(123, 63%, 88%);
  border-bottom-color: hsl(65, 95%, 32%);
}

td[class^=jackdaws-diamond]:before {
  border-width: 0.375rem;
}

.jackdaws--primary {
  background: hsl(0, 0%, 0%);
}

.jackdaws--secondary {
  background: hsl(264, 12%, 58%);
}

.jackdaws--away {
  background: hsl(123, 63%, 88%);
}

.jackdaws--other {
  background: hsl(65, 95%, 32%);
}

.fill--atomic {
  fill: hsl(51, 98%, 50%);
}

[class^=atomic-]:before {
  margin-right: 1rem;
}

[class^=atomic-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=atomic-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(51, 98%, 50%);
  border-left-color: hsl(180, 74%, 49%);
  border-top-color: hsl(317, 90%, 57%);
  border-bottom-color: hsl(230, 72%, 49%);
}

td[class^=atomic-diamond]:before {
  border-width: 0.375rem;
}

.atomic--primary {
  background: hsl(51, 98%, 50%);
}

.atomic--secondary {
  background: hsl(180, 74%, 49%);
}

.atomic--away {
  background: hsl(317, 90%, 57%);
}

.atomic--other {
  background: hsl(230, 72%, 49%);
}

.fill--pearls {
  fill: hsl(191, 64%, 68%);
}

[class^=pearls-]:before {
  margin-right: 1rem;
}

[class^=pearls-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=pearls-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(191, 64%, 68%);
  border-left-color: hsl(4, 100%, 51%);
  border-top-color: hsl(60, 98%, 49%);
  border-bottom-color: hsl(215, 98%, 49%);
}

td[class^=pearls-diamond]:before {
  border-width: 0.375rem;
}

.pearls--primary {
  background: hsl(191, 64%, 68%);
}

.pearls--secondary {
  background: hsl(4, 100%, 51%);
}

.pearls--away {
  background: hsl(60, 98%, 49%);
}

.pearls--other {
  background: hsl(215, 98%, 49%);
}

.fill--aztecs {
  fill: hsl(0, 100%, 72%);
}

[class^=aztecs-]:before {
  margin-right: 1rem;
}

[class^=aztecs-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=aztecs-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 100%, 72%);
  border-left-color: hsl(11, 73%, 36%);
  border-top-color: hsl(43, 100%, 61%);
  border-bottom-color: hsl(157, 73%, 60%);
}

td[class^=aztecs-diamond]:before {
  border-width: 0.375rem;
}

.aztecs--primary {
  background: hsl(0, 100%, 72%);
}

.aztecs--secondary {
  background: hsl(11, 73%, 36%);
}

.aztecs--away {
  background: hsl(43, 100%, 61%);
}

.aztecs--other {
  background: hsl(157, 73%, 60%);
}

.fill--comets {
  fill: hsl(348, 59%, 33%);
}

[class^=comets-]:before {
  margin-right: 1rem;
}

[class^=comets-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=comets-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(348, 59%, 33%);
  border-left-color: hsl(36, 71%, 48%);
  border-top-color: hsl(215, 36%, 59%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=comets-diamond]:before {
  border-width: 0.375rem;
}

.comets--primary {
  background: hsl(348, 59%, 33%);
}

.comets--secondary {
  background: hsl(36, 71%, 48%);
}

.comets--away {
  background: hsl(215, 36%, 59%);
}

.comets--other {
  background: hsl(0, 0%, 100%);
}

.fill--vipers {
  fill: hsl(103, 73%, 15%);
}

[class^=vipers-]:before {
  margin-right: 1rem;
}

[class^=vipers-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=vipers-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(103, 73%, 15%);
  border-left-color: hsl(53, 93%, 53%);
  border-top-color: hsl(21, 93%, 53%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=vipers-diamond]:before {
  border-width: 0.375rem;
}

.vipers--primary {
  background: hsl(103, 73%, 15%);
}

.vipers--secondary {
  background: hsl(53, 93%, 53%);
}

.vipers--away {
  background: hsl(21, 93%, 53%);
}

.vipers--other {
  background: hsl(0, 0%, 100%);
}

.fill--vipers {
  fill: hsl(103, 73%, 15%);
}

[class^=vipers-]:before {
  margin-right: 1rem;
}

[class^=vipers-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=vipers-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(103, 73%, 15%);
  border-left-color: hsl(53, 93%, 53%);
  border-top-color: hsl(21, 93%, 53%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=vipers-diamond]:before {
  border-width: 0.375rem;
}

.vipers--primary {
  background: hsl(103, 73%, 15%);
}

.vipers--secondary {
  background: hsl(53, 93%, 53%);
}

.vipers--away {
  background: hsl(21, 93%, 53%);
}

.vipers--other {
  background: hsl(0, 0%, 100%);
}

.fill--riders {
  fill: hsl(0, 0%, 0%);
}

[class^=riders-]:before {
  margin-right: 1rem;
}

[class^=riders-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=riders-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 0%, 0%);
  border-left-color: hsl(0, 0%, 58%);
  border-top-color: hsl(178, 96%, 27%);
  border-bottom-color: hsl(177, 16%, 76%);
}

td[class^=riders-diamond]:before {
  border-width: 0.375rem;
}

.riders--primary {
  background: hsl(0, 0%, 0%);
}

.riders--secondary {
  background: hsl(0, 0%, 58%);
}

.riders--away {
  background: hsl(178, 96%, 27%);
}

.riders--other {
  background: hsl(177, 16%, 76%);
}

.fill--major {
  fill: hsl(233, 67%, 25%);
}

[class^=major-]:before {
  margin-right: 1rem;
}

[class^=major-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=major-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(233, 67%, 25%);
  border-left-color: hsl(48, 100%, 50%);
  border-top-color: hsl(284, 80%, 29%);
  border-bottom-color: hsl(311, 100%, 45%);
}

td[class^=major-diamond]:before {
  border-width: 0.375rem;
}

.major--primary {
  background: hsl(233, 67%, 25%);
}

.major--secondary {
  background: hsl(48, 100%, 50%);
}

.major--away {
  background: hsl(284, 80%, 29%);
}

.major--other {
  background: hsl(311, 100%, 45%);
}

.fill--angels {
  fill: hsl(6, 100%, 45%);
}

[class^=angels-]:before {
  margin-right: 1rem;
}

[class^=angels-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=angels-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(6, 100%, 45%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(146, 92%, 21%);
  border-bottom-color: hsl(49, 95%, 50%);
}

td[class^=angels-diamond]:before {
  border-width: 0.375rem;
}

.angels--primary {
  background: hsl(6, 100%, 45%);
}

.angels--secondary {
  background: hsl(0, 0%, 100%);
}

.angels--away {
  background: hsl(146, 92%, 21%);
}

.angels--other {
  background: hsl(49, 95%, 50%);
}

.fill--panthers {
  fill: hsl(0, 0%, 0%);
}

[class^=panthers-]:before {
  margin-right: 1rem;
}

[class^=panthers-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=panthers-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 0%, 0%);
  border-left-color: hsl(43, 97%, 45%);
  border-top-color: hsl(168, 96%, 45%);
  border-bottom-color: hsl(200, 100%, 28%);
}

td[class^=panthers-diamond]:before {
  border-width: 0.375rem;
}

.panthers--primary {
  background: hsl(0, 0%, 0%);
}

.panthers--secondary {
  background: hsl(43, 97%, 45%);
}

.panthers--away {
  background: hsl(168, 96%, 45%);
}

.panthers--other {
  background: hsl(200, 100%, 28%);
}

.fill--magpies {
  fill: hsl(0, 0%, 0%);
}

[class^=magpies-]:before {
  margin-right: 1rem;
}

[class^=magpies-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=magpies-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(0, 0%, 0%);
  border-left-color: hsl(249, 32%, 25%);
  border-top-color: hsl(224, 78%, 76%);
  border-bottom-color: hsl(55, 87%, 56%);
}

td[class^=magpies-diamond]:before {
  border-width: 0.375rem;
}

.magpies--primary {
  background: hsl(0, 0%, 0%);
}

.magpies--secondary {
  background: hsl(249, 32%, 25%);
}

.magpies--away {
  background: hsl(224, 78%, 76%);
}

.magpies--other {
  background: hsl(55, 87%, 56%);
}

.fill--jets {
  fill: hsl(240, 46%, 49%);
}

[class^=jets-]:before {
  margin-right: 1rem;
}

[class^=jets-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=jets-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(240, 46%, 49%);
  border-left-color: hsl(0, 0%, 100%);
  border-top-color: hsl(344, 98%, 49%);
  border-bottom-color: hsl(225, 69%, 73%);
}

td[class^=jets-diamond]:before {
  border-width: 0.375rem;
}

.jets--primary {
  background: hsl(240, 46%, 49%);
}

.jets--secondary {
  background: hsl(0, 0%, 100%);
}

.jets--away {
  background: hsl(344, 98%, 49%);
}

.jets--other {
  background: hsl(225, 69%, 73%);
}

.fill--roses {
  fill: hsl(343, 100%, 74%);
}

[class^=roses-]:before {
  margin-right: 1rem;
}

[class^=roses-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=roses-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(343, 100%, 74%);
  border-left-color: hsl(336, 88%, 35%);
  border-top-color: hsl(234, 19%, 79%);
  border-bottom-color: hsl(236, 32%, 20%);
}

td[class^=roses-diamond]:before {
  border-width: 0.375rem;
}

.roses--primary {
  background: hsl(343, 100%, 74%);
}

.roses--secondary {
  background: hsl(336, 88%, 35%);
}

.roses--away {
  background: hsl(234, 19%, 79%);
}

.roses--other {
  background: hsl(236, 32%, 20%);
}

.fill--bobolinks {
  fill: hsl(48, 93%, 65%);
}

[class^=bobolinks-]:before {
  margin-right: 1rem;
}

[class^=bobolinks-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=bobolinks-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(48, 93%, 65%);
  border-left-color: hsl(0, 0%, 0%);
  border-top-color: hsl(256, 49%, 62%);
  border-bottom-color: hsl(0, 0%, 100%);
}

td[class^=bobolinks-diamond]:before {
  border-width: 0.375rem;
}

.bobolinks--primary {
  background: hsl(48, 93%, 65%);
}

.bobolinks--secondary {
  background: hsl(0, 0%, 0%);
}

.bobolinks--away {
  background: hsl(256, 49%, 62%);
}

.bobolinks--other {
  background: hsl(0, 0%, 100%);
}

.fill--indigos {
  fill: hsl(231, 42%, 29%);
}

[class^=indigos-]:before {
  margin-right: 1rem;
}

[class^=indigos-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=indigos-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(231, 42%, 29%);
  border-left-color: hsl(217, 46%, 41%);
  border-top-color: hsl(0, 0%, 100%);
  border-bottom-color: hsl(208, 21%, 82%);
}

td[class^=indigos-diamond]:before {
  border-width: 0.375rem;
}

.indigos--primary {
  background: hsl(231, 42%, 29%);
}

.indigos--secondary {
  background: hsl(217, 46%, 41%);
}

.indigos--away {
  background: hsl(0, 0%, 100%);
}

.indigos--other {
  background: hsl(208, 21%, 82%);
}

.fill--storm {
  fill: hsl(37, 95%, 50%);
}

[class^=storm-]:before {
  margin-right: 1rem;
}

[class^=storm-diamond-loading]:before {
  -webkit-animation: rotating-diamond 2s linear infinite;
  -moz-animation: rotating-diamond 2s linear infinite;
  -ms-animation: rotating-diamond 2s linear infinite;
  -o-animation: rotating-diamond 2s linear infinite;
  animation: rotating-diamond 2s linear infinite;
  display: inline-block;
}

[class^=storm-diamond]:before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 0.5rem;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
  border-right-color: hsl(37, 95%, 50%);
  border-left-color: hsl(275, 48%, 45%);
  border-top-color: hsl(204, 97%, 64%);
  border-bottom-color: hsl(218, 98%, 50%);
}

td[class^=storm-diamond]:before {
  border-width: 0.375rem;
}

.storm--primary {
  background: hsl(37, 95%, 50%);
}

.storm--secondary {
  background: hsl(275, 48%, 45%);
}

.storm--away {
  background: hsl(204, 97%, 64%);
}

.storm--other {
  background: hsl(218, 98%, 50%);
}

@media all and (max-width: 896px) {
  td[class$=-diamond]::before,
  td[class$=shield]::before,
  td[class$=-concentric]::before {
    display: none;
  }
}
[class$="--above"]:before {
  margin-right: 0;
}

.focus {
  border: 1px dotted black;
  padding: 2rem;
}

.onClick {
  cursor: pointer;
}

.list-tabs {
  position: relative;
}
.list-tabs:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid gray;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.list-tabs li {
  border: 1px solid gray;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 10;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.list-tabs .current {
  background: white;
  font-weight: 900;
  border-bottom: 1px solid white;
}

.jersey {
  max-width: 40rem;
  display: block;
  margin: 1rem;
}

.card.alert {
  background: #f58162;
}

.label__transfer-available {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  background: #f58162;
  color: black;
  padding: 0.5rem;
  font-size: 0.75rem;
}

.icon--transfer-listed::before {
  background: rgb(191, 176, 255);
  color: black;
}

.icon--loan-listed::before {
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.icon--transfer-listed::before,
.icon--loan-listed::before {
  font-style: normal;
  font-size: 0.7rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: inline-grid;
  align-items: center;
  justify-content: center;
}

.icon--transfer-listed::before {
  content: "T";
}

.icon--loan-listed::before {
  content: "L";
}

.icon--shirt {
  border-radius: 50%;
  width: 2ch;
  text-align: center;
  display: inline-block;
  color: white;
  padding: 0.5ch;
  font-style: normal;
}

.d-f {
  display: flex;
}

.fixture-row__date {
  margin-bottom: 0.5rem;
}

.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
  max-width: 6rem;
  overflow: hidden;
}
.stars::before {
  content: "★★★★★";
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 30;
}

.warning {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: red;
  }
  100% {
    background-color: transparent;
  }
}
.buried {
  margin-top: 3rem;
  text-align: center;
  padding: 1rem;
}

.inbox-link {
  display: block;
  width: 2ch;
  position: relative;
}

[class^=formation--],
.penalty-area {
  z-index: 0;
}

[class^=formation--] {
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 20rem;
}

.penalty-area,
.center-circle,
.penalty-arc,
.six-yard {
  border: 0.2rem solid hsla(0, 0%, 100%, 0.4);
  content: "";
  position: absolute;
}

.penalty-area,
.center-circle {
  z-index: 0;
}

.six-yard {
  z-index: 10;
}

.penalty-area,
.six-yard {
  bottom: 0;
  border-bottom: none;
}

.penalty-area {
  height: 6rem;
  left: 20%;
  right: 20%;
}

.six-yard {
  left: 30%;
  right: 30%;
  top: 90%;
}

.center-circle,
.penalty-arc {
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  margin-left: -5rem;
  left: 50%;
}

.center-circle {
  top: -5rem;
}

.penalty-arc {
  bottom: -2rem;
  z-index: -10;
}

[class^=formation--] {
  display: grid;
  min-width: 15rem;
  max-width: 30rem;
  grid-template-rows: repeat(4, 25%);
  grid-template-columns: repeat(9, 11.1111111111%);
}
[class^=formation--] .dot-1 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-2 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-5 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-6 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-7 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-8 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-9 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-10 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--] .dot-11 {
  place-self: center;
}
[class^=formation--] .dot-1 {
  grid-column-start: 5;
  grid-row-start: 4;
}
[class^=formation--] .dot-4 {
  grid-column-start: 3;
}
[class^=formation--] .dot-3 {
  grid-column-start: 5;
}
[class^=formation--] .dot-2 {
  grid-column-start: 7;
}
[class^=formation--] .dot-2 {
  grid-row-start: 3;
}
[class^=formation--] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--] .dot-4 {
  grid-row-start: 3;
}

[class^=formation--4] .dot-5 {
  grid-column-start: 2;
}
[class^=formation--4] .dot-4 {
  grid-column-start: 4;
}
[class^=formation--4] .dot-3 {
  grid-column-start: 6;
}
[class^=formation--4] .dot-2 {
  grid-column-start: 8;
}
[class^=formation--4] .dot-5 {
  grid-row-start: 3;
}
[class^=formation--4] .dot-4 {
  grid-row-start: 3;
}
[class^=formation--4] .dot-3 {
  grid-row-start: 3;
}
[class^=formation--4] .dot-2 {
  grid-row-start: 3;
}

.formation--4-4-2 .dot-10, .formation--4-4-2 .dot-11 {
  grid-row-start: 1;
}
.formation--4-4-2 .dot-6 {
  grid-row-start: 2;
}
.formation--4-4-2 .dot-7 {
  grid-row-start: 2;
}
.formation--4-4-2 .dot-8 {
  grid-row-start: 2;
}
.formation--4-4-2 .dot-9 {
  grid-row-start: 2;
}
.formation--4-4-2 .dot-11 {
  grid-column-start: 4;
}
.formation--4-4-2 .dot-10 {
  grid-column-start: 6;
}
.formation--4-4-2 .dot-9 {
  grid-column-start: 2;
}
.formation--4-4-2 .dot-8 {
  grid-column-start: 4;
}
.formation--4-4-2 .dot-7 {
  grid-column-start: 6;
}
.formation--4-4-2 .dot-6 {
  grid-column-start: 8;
}

.formation--4-5-1 .dot-6 {
  grid-row-start: 2;
}
.formation--4-5-1 .dot-10 {
  grid-column-start: 1;
}
.formation--4-5-1 .dot-9 {
  grid-column-start: 3;
}
.formation--4-5-1 .dot-8 {
  grid-column-start: 5;
}
.formation--4-5-1 .dot-7 {
  grid-column-start: 7;
}
.formation--4-5-1 .dot-6 {
  grid-column-start: 9;
}
.formation--4-5-1 .dot-7 {
  grid-row-start: 2;
}
.formation--4-5-1 .dot-10 {
  grid-column-start: 1;
}
.formation--4-5-1 .dot-9 {
  grid-column-start: 3;
}
.formation--4-5-1 .dot-8 {
  grid-column-start: 5;
}
.formation--4-5-1 .dot-7 {
  grid-column-start: 7;
}
.formation--4-5-1 .dot-6 {
  grid-column-start: 9;
}
.formation--4-5-1 .dot-8 {
  grid-row-start: 2;
}
.formation--4-5-1 .dot-10 {
  grid-column-start: 1;
}
.formation--4-5-1 .dot-9 {
  grid-column-start: 3;
}
.formation--4-5-1 .dot-8 {
  grid-column-start: 5;
}
.formation--4-5-1 .dot-7 {
  grid-column-start: 7;
}
.formation--4-5-1 .dot-6 {
  grid-column-start: 9;
}
.formation--4-5-1 .dot-9 {
  grid-row-start: 2;
}
.formation--4-5-1 .dot-10 {
  grid-column-start: 1;
}
.formation--4-5-1 .dot-9 {
  grid-column-start: 3;
}
.formation--4-5-1 .dot-8 {
  grid-column-start: 5;
}
.formation--4-5-1 .dot-7 {
  grid-column-start: 7;
}
.formation--4-5-1 .dot-6 {
  grid-column-start: 9;
}
.formation--4-5-1 .dot-10 {
  grid-row-start: 2;
}
.formation--4-5-1 .dot-10 {
  grid-column-start: 1;
}
.formation--4-5-1 .dot-9 {
  grid-column-start: 3;
}
.formation--4-5-1 .dot-8 {
  grid-column-start: 5;
}
.formation--4-5-1 .dot-7 {
  grid-column-start: 7;
}
.formation--4-5-1 .dot-6 {
  grid-column-start: 9;
}
.formation--4-5-1 .dot-11 {
  grid-column-start: 5;
  grid-row-start: 1;
}

.formation--4-3-3 .dot-9 {
  grid-row-start: 1;
}
.formation--4-3-3 .dot-10 {
  grid-row-start: 1;
}
.formation--4-3-3 .dot-11 {
  grid-row-start: 1;
}
.formation--4-3-3 .dot-6 {
  grid-row-start: 2;
}
.formation--4-3-3 .dot-11, .formation--4-3-3 .dot-8 {
  grid-column-start: 3;
}
.formation--4-3-3 .dot-10, .formation--4-3-3 .dot-7 {
  grid-column-start: 5;
}
.formation--4-3-3 .dot-9, .formation--4-3-3 .dot-6 {
  grid-column-start: 7;
}
.formation--4-3-3 .dot-7 {
  grid-row-start: 2;
}
.formation--4-3-3 .dot-11, .formation--4-3-3 .dot-8 {
  grid-column-start: 3;
}
.formation--4-3-3 .dot-10, .formation--4-3-3 .dot-7 {
  grid-column-start: 5;
}
.formation--4-3-3 .dot-9, .formation--4-3-3 .dot-6 {
  grid-column-start: 7;
}
.formation--4-3-3 .dot-8 {
  grid-row-start: 2;
}
.formation--4-3-3 .dot-11, .formation--4-3-3 .dot-8 {
  grid-column-start: 3;
}
.formation--4-3-3 .dot-10, .formation--4-3-3 .dot-7 {
  grid-column-start: 5;
}
.formation--4-3-3 .dot-9, .formation--4-3-3 .dot-6 {
  grid-column-start: 7;
}

.formation--3-4-3 .dot-9 {
  grid-row-start: 1;
}
.formation--3-4-3 .dot-10 {
  grid-row-start: 1;
}
.formation--3-4-3 .dot-11 {
  grid-row-start: 1;
}
.formation--3-4-3 .dot-5 {
  grid-row-start: 2;
}
.formation--3-4-3 .dot-6 {
  grid-row-start: 2;
}
.formation--3-4-3 .dot-7 {
  grid-row-start: 2;
}
.formation--3-4-3 .dot-8 {
  grid-row-start: 2;
}
.formation--3-4-3 .dot-2 {
  grid-row-start: 3;
}
.formation--3-4-3 .dot-3 {
  grid-row-start: 3;
}
.formation--3-4-3 .dot-4 {
  grid-row-start: 3;
}
.formation--3-4-3 .dot-5 {
  grid-column-start: 2;
}
.formation--3-4-3 .dot-6 {
  grid-column-start: 4;
}
.formation--3-4-3 .dot-7 {
  grid-column-start: 6;
}
.formation--3-4-3 .dot-8 {
  grid-column-start: 8;
}
.formation--3-4-3 .dot-11 {
  grid-column-start: 3;
}
.formation--3-4-3 .dot-10 {
  grid-column-start: 5;
}
.formation--3-4-3 .dot-9 {
  grid-column-start: 7;
}

.formation--3-5-2 .dot-10 {
  grid-row-start: 1;
}
.formation--3-5-2 .dot-11 {
  grid-row-start: 1;
}
.formation--3-5-2 .dot-5 {
  grid-row-start: 2;
}
.formation--3-5-2 .dot-6 {
  grid-row-start: 2;
}
.formation--3-5-2 .dot-7 {
  grid-row-start: 2;
}
.formation--3-5-2 .dot-8 {
  grid-row-start: 2;
}
.formation--3-5-2 .dot-9 {
  grid-row-start: 2;
}
.formation--3-5-2 .dot-2 {
  grid-row-start: 3;
}
.formation--3-5-2 .dot-3 {
  grid-row-start: 3;
}
.formation--3-5-2 .dot-4 {
  grid-row-start: 3;
}
.formation--3-5-2 .dot-11 {
  grid-column-start: 4;
}
.formation--3-5-2 .dot-10 {
  grid-column-start: 6;
}
.formation--3-5-2 .dot-9 {
  grid-column-start: 3;
}
.formation--3-5-2 .dot-8 {
  grid-column-start: 5;
}
.formation--3-5-2 .dot-7 {
  grid-column-start: 7;
}
.formation--3-5-2 .dot-5 {
  grid-column-start: 1;
}
.formation--3-5-2 .dot-6 {
  grid-column-start: 9;
}

.blur {
  color: transparent;
  text-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.5);
  user-select: none;
}

.visually-hidden {
  position: absolute;
  left: -100vw;
}

.center, [class^=notification],
.c {
  text-align: center;
}

.a-l {
  text-align: left;
}

.a-r {
  text-align: right;
}

.f-l {
  float: left;
}

.f-r {
  float: right;
}

.clear {
  clear: both;
}

.ml0-5 {
  margin-left: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.m1 {
  margin: 1rem;
}

.m-h-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m-v-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.zero, .list-inline, .list-tabs {
  margin: 0;
}

.red {
  color: red;
}

.green {
  color: green;
}

.flex-r {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media all and (max-width: 896px) {
  .h-s {
    display: none;
  }
}
@media all and (min-width: 896px) {
  .h-l {
    display: none;
  }
}
@media all and (max-width: 100ch) {
  .w-tablet {
    display: none;
  }
}
@media all and (min-width: 100ch) {
  .quarters + .quarters,
  .thirds + .thirds {
    margin-top: 2rem;
  }
}
@media all and (max-width: 896px) {
  .nav > .nav-links {
    position: fixed;
    background: white;
    z-index: 20;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2rem 1rem 2rem 2rem;
    display: none;
  }
  .nav > .nav-links .nav > .nav-links {
    display: block;
  }
}
@media all and (min-width: 896px) {
  .page {
    grid-area: page;
  }
  .all {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto;
    grid-template-areas: "masthead masthead" "nav-horizontal nav-horizontal" "nav page";
  }
  .nav {
    display: block;
  }
}
.quarters,
.thirds {
  display: grid;
}

.thirds {
  grid-template-columns: repeat(3, 1fr);
}

.quarters {
  grid-template-columns: repeat(4, 1fr);
}
